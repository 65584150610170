import React from 'react'
import CommonListOption, { AsHeadlessType } from '../../form/common-list-option'
import { DataInterface, ExtendedDataType, GroupDataInterface } from '../index'

interface BaseListOptionsProps<T extends ExtendedDataType> {
  data?: T[]
  value?: DataInterface<T>['id']
  getOptionDataAttribute?: (value?: string) => void
  group?: GroupDataInterface
  optionIconRenderer?: (data?: T) => JSX.Element | undefined
  as: AsHeadlessType
}

function BaseListOptions<T extends ExtendedDataType>({
  data,
  value,
  getOptionDataAttribute,
  group,
  optionIconRenderer,
  as,
}: BaseListOptionsProps<T>) {
  const filteredItems = group ? data?.filter(item => item.group === group.id) : data
  const isGroupTitleVisible = group && filteredItems && filteredItems.length >= 1

  return (
    <>
      {isGroupTitleVisible && (
        <div className="flex items-center h-6 flex-shrink-0 bg-accent/50 border-gray/30 border border-t-0">
          <span className="px-1.5 text-darkblue font-bold">{group.label}</span>
        </div>
      )}
      {filteredItems?.map(item => (
        <CommonListOption
          item={item}
          key={item.id}
          value={value}
          disabled={item.disabled}
          getOptionDataAttribute={getOptionDataAttribute}
          optionIconRenderer={optionIconRenderer}
          as={as}
        />
      ))}
    </>
  )
}

export default BaseListOptions
