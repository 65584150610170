import { Float } from '@headlessui-float/react'
import { Combobox } from '@headlessui/react'
import React, { ElementType, ReactNode, useEffect, useRef, useState } from 'react'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import ArrowDown from 'shared/icons/arrow-down-icon'
import CloseIcon from 'shared/icons/close-icon'
import {
  FieldErrorAndDescription,
  FieldErrorAndDescriptionProps,
} from '../form/field-error-and-description'
import { FieldLabel, FieldLabelProps } from '../form/field-label'
import { Loader } from '../loader'
import BaseList from './base-list'
import VirtualizedList from './virtualized-list'

export type ExtendedDataType = {
  id: string | number | null | undefined
  caption: string
  customCaption?: ReactNode
  disabled?: boolean
  group?: string
}

export interface DataInterface<T extends ExtendedDataType> {
  id: T['id']
  caption: string
}

export interface GroupDataInterface {
  id: string
  label: string
}

export type FormSelectProps<T extends ExtendedDataType = ExtendedDataType> =
  FieldErrorAndDescriptionProps &
    FieldLabelProps & {
      formSelectRef?: React.MutableRefObject<{
        buttonRef: React.RefObject<HTMLButtonElement>
      } | null>
      htmlFor?: string
      data: T[] | undefined
      staticData?: T[]
      onChange: (data: DataInterface<T>['id']) => void
      isPreFetching?: boolean
      value?: DataInterface<T>['id']
      placeholder?: string
      className?: string
      wrapperClassName?: string
      disabled?: boolean
      withoutCloseIcon?: boolean
      withoutVirtualization?: boolean
      onOpen?: () => void
      small?: boolean
      createData?: {
        setCreateData: (data: { name: string }) => void
        getCustomCaption: (data: string) => string
      }
      isCreating?: boolean
      dataInputAttribute?: string
      floatWrapper?: ElementType
      getOptionDataAttribute?: (value?: string) => void
      groups?: GroupDataInterface[]
      optionIconRenderer?: (data?: T) => JSX.Element | undefined
    }

const defaultPlaceholder = 'global.select'

const CREATE_DATA_OPTION_ID = null

function FormSelect<T extends ExtendedDataType>({
  formSelectRef,
  label,
  data,
  staticData,
  htmlFor,
  onChange,
  labelClassName,
  value,
  isPreFetching,
  placeholder,
  error,
  required,
  description,
  className,
  wrapperClassName,
  disabled,
  withoutCloseIcon,
  withoutVirtualization: isWithoutVirtualScroll,
  onOpen,
  small,
  createData,
  isCreating,
  dataInputAttribute,
  floatWrapper,
  getOptionDataAttribute,
  groups,
  optionIconRenderer,
}: FormSelectProps<T>) {
  const { t } = useLocoTranslation()

  const [opened, setOpened] = useState(false)

  useEffect(() => {
    if (opened && onOpen) {
      onOpen()
      setOpened(false)
    }
  }, [onOpen, opened])

  const dropdownRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)
  const [buttonWidth, setButtonWidth] = useState(400)

  const [selectedData, setSelectedData] = useState<T | null>(
    data
      ? data.find(el => el.id == value) || null
      : staticData
      ? staticData.find(el => el.id == value) || null
      : null,
  )

  useEffect(() => {
    if (!isCreating) {
      setSelectedData(
        data
          ? data.find(el => el.id == value) || null
          : staticData
          ? staticData.find(el => el.id == value) || null
          : null,
      )
    }
  }, [data, value, staticData])

  const [query, setQuery] = useState('')

  const [filteredData, setFilteredData] = useState(data)

  useEffect(() => {
    const filteredData =
      query === ''
        ? data
        : data?.filter((item: T) =>
            item.caption
              .toLowerCase()
              .replace(/\s+/g, '')
              .includes(query.toLowerCase().replace(/\s+/g, '')),
          ) ?? []
    if (createData && query !== '') {
      filteredData?.unshift({
        id: CREATE_DATA_OPTION_ID,
        caption: query,
        customCaption: createData.getCustomCaption(query),
      } as T)
    }
    setFilteredData(() => filteredData)
  }, [data, query])

  const disabledNoDataWithValue = !staticData && value && !data
  const isVirtualizedList = (filteredData || []).length > 20 && !isWithoutVirtualScroll

  if (formSelectRef) {
    formSelectRef.current = {
      buttonRef,
    }
  }

  return (
    <div className={`relative flex flex-col gap-1 ${wrapperClassName || ''}`}>
      <Combobox
        value={selectedData}
        onChange={data => {
          if (createData && data && data.id === CREATE_DATA_OPTION_ID) {
            createData.setCreateData({ name: data.caption })
            setSelectedData({ id: data.id, caption: data.caption } as T)
          } else data && onChange(data.id)
        }}
        disabled={disabled || isPreFetching || disabledNoDataWithValue}
        nullable
      >
        {({ open }) => {
          return (
            <Float
              offset={4}
              portal
              onUpdate={() => {
                if (dropdownRef.current && inputRef.current) {
                  const buttonWidth = inputRef.current.getBoundingClientRect().width
                  setButtonWidth(buttonWidth)
                  dropdownRef.current.style.width = `${buttonWidth}px`
                }
              }}
              flip
              as={'div'}
              onHide={() => setQuery('')}
              {...(floatWrapper ? { floatingAs: floatWrapper } : {})}
            >
              <div className="flex flex-col gap-1">
                <FieldLabel
                  wrapperAs={Combobox.Label}
                  wrapperProps={{ htmlFor: htmlFor }}
                  label={label}
                  required={required}
                  labelClassName={`text-sm font-medium ${labelClassName}`}
                />

                <Combobox.Button
                  ref={buttonRef}
                  as={'div'}
                  className="relative w-full cursor-default overflow-hidden rounded-lg text-left"
                >
                  <Combobox.Input
                    className={`truncate ${
                      small ? '' : 'lg:min-w-[200px]'
                    } relative flex justify-between items-center w-full pr-[59px] py-2.5 pl-4 rounded-lg ${
                      disabled || disabledNoDataWithValue
                        ? isPreFetching
                          ? 'bg-gray-600/40'
                          : 'bg-gray-200/40'
                        : 'bg-white'
                    } text-darkblue main-transition-colors cursor-default text-left text-sm border ${
                      open ? 'border-blue' : error ? 'border-danger' : 'border-gray/30'
                    } ${open && 'border-blue'} focus:outline-none focus-visible:border-blue ${
                      className || ''
                    } ${isPreFetching && 'animate-pulse bg-gray-600/40'} ${
                      isPreFetching ? 'text-transparent' : 'text-darkblue'
                    } ${
                      isPreFetching
                        ? 'placeholder:text-transparent'
                        : 'placeholder:text-gray-300/70'
                    } ${isCreating && 'animate-pulse bg-gray-600/40'}`}
                    ref={inputRef}
                    onChange={event => {
                      setOpened(true)
                      setQuery(event.target.value)
                    }}
                    onFocus={() => {
                      setOpened(true)
                    }}
                    displayValue={(item: T | null) => (item ? item.caption : '')}
                    placeholder={placeholder || t(defaultPlaceholder)}
                    {...(dataInputAttribute ? { ['data-test-element']: dataInputAttribute } : {})}
                  />
                  {value !== undefined && !withoutCloseIcon && (
                    <div
                      onClick={e => {
                        if (disabled || disabledNoDataWithValue || isPreFetching) return
                        e.stopPropagation()
                        onChange(undefined!)
                      }}
                      className={`absolute inset-y-0 right-0 flex items-center pr-10`}
                    >
                      <CloseIcon
                        className={`${
                          disabled || disabledNoDataWithValue || isPreFetching
                            ? 'cursor-default fill-transparent'
                            : 'cursor-pointer fill-darkblue'
                        }`}
                      />
                    </div>
                  )}
                  <div className="absolute inset-y-0 right-0 flex items-center pr-4">
                    <ArrowDown
                      className={`${
                        disabled || disabledNoDataWithValue || isPreFetching
                          ? 'cursor-default stroke-transparent'
                          : 'cursor-pointer stroke-darkblue'
                      } transition-transform duration-300 ${open && 'rotate-180'}`}
                      aria-hidden="true"
                    />
                  </div>
                </Combobox.Button>
              </div>
              <Combobox.Options className={'relative focus-visible:outline-none'}>
                <div ref={dropdownRef} className={'bg-white rounded-lg'}>
                  {data === undefined ? (
                    <div className="flex bg-white justify-center px-4 py-1 border rounded-md border-gray/30">
                      <Loader className="scale-75" />
                    </div>
                  ) : isVirtualizedList ? (
                    <VirtualizedList
                      data={filteredData}
                      value={value}
                      buttonWidth={buttonWidth}
                      getOptionDataAttribute={getOptionDataAttribute}
                      optionIconRenderer={optionIconRenderer}
                      as={Combobox.Option}
                    />
                  ) : (
                    <BaseList
                      data={filteredData}
                      value={value}
                      getOptionDataAttribute={getOptionDataAttribute}
                      groups={groups}
                      optionIconRenderer={optionIconRenderer}
                      as={Combobox.Option}
                    />
                  )}
                </div>
              </Combobox.Options>
            </Float>
          )
        }}
      </Combobox>
      <FieldErrorAndDescription description={description} error={error} />
    </div>
  )
}

export default FormSelect
